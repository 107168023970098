import React from 'react'
import AuthCode, { AuthCodeProps } from 'react-auth-code-input'

interface CustomAuthCodeProps extends AuthCodeProps {}

export default function CustomAuthCode({
	onChange,
	...otherProps
}: CustomAuthCodeProps) {
	return (
		<AuthCode
			onChange={onChange}
			allowedCharacters="numeric"
			inputClassName="auth-input"
			{...otherProps}
		/>
	)
}
