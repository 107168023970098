import gql from 'graphql-tag'

export const LOGIN = gql`
	mutation login($username: String!, $password: String!, $otp: String!) {
		login(username: $username, password: $password, otp: $otp) {
			errors {
				path
				message
			}
			tokens
			needOTP
			delta
		}
	}
`

export const LOGOUT = gql`
	mutation logout {
		logout
	}
`
